import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Profile } from './components'
import { Container, Menu, MenuItem, Toolbar } from './styles'

import logo from './assets/header-logo.png'

import { PROFILE_STORAGE_KEY } from '../../auth/context/AuthContext'

export const Header: React.FC = () => {
  const [privilege, setPrivilege] = useState<number | null>(null)

  const { pathname } = useLocation()

  const handleGetProfileType = () => {
    const profile = localStorage.getItem(PROFILE_STORAGE_KEY)
    if (!profile) {
      return
    }

    setPrivilege(Number(profile))
  }

  useEffect(() => {
    handleGetProfileType()
  }, [])

  const left =
    privilege !== 0
      ? '50%'
      : pathname === '/dashboard/visualizacoes'
      ? '16.6666667%'
      : pathname === '/dashboard/repasse'
      ? '50%'
      : '83.3333333%'

  console.log('privilegde', privilege)

  return (
    <Container>
      <h1>
        <img src={logo} alt="logo Bamboleio" />
      </h1>
      <Toolbar>
        <Menu left={left} count={privilege === 0 ? 3 : 1}>
          {privilege === 0 && (
            <>
              <MenuItem to="/dashboard/visualizacoes" end>
                Visualizações
              </MenuItem>
              <MenuItem to="/dashboard/repasse" end>
                Cálculo de repasse
              </MenuItem>
            </>
          )}
          <MenuItem to="/dashboard/relatorios">Relatórios</MenuItem>
        </Menu>
        <Profile />
      </Toolbar>
    </Container>
  )
}
